import React from 'react';

import './social-links.styles.scss';

const SocialLinks = () => {
    return (
        <div className="mt-4">
            <a href="https://www.linkedin.com/in/fbacon/" target="_blank" rel="noreferrer">
                <i
                    className="bi bi-linkedin p-1 sc-button"
                ></i>
            </a>
            {/* <i
                class="bi bi-facebook p-1"
                style={{ fontSize: '2rem', color: 'white' }}
            ></i> */}
            <a href="https://github.com/FreemanDigital" target="_blank" rel="noreferrer">
                <i
                    className="bi bi-github p-1 sc-button"
                ></i>
            </a>
        </div>
    );
};

export default SocialLinks;