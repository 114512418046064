import AvatarImage from '../assets/images/self_portrait_1.png';

import './avatar.styles.scss';

const Avatar = () => {
    return (
        <div
            className="avatar-container p-3"
            style={{
                width: '100%',
                height: 'auto',
            }}
        >
            <img
                src={AvatarImage}
                className="avatar img-fluid"
                alt="Freeman Bacon"
                style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '50%',
                    boxShadow: '0px 5px 2px 0px rgba(233,233,233,1)',
                }}
            />
        </div>
    );
};

export default Avatar;
