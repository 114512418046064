import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './fade-slide-wrapper.styles.scss';

const FadeAndSlideHorizontal = ({ children, in: inProp }) => {
  return (
    <CSSTransition
      in={inProp}
      timeout={300}
      classNames="fade-slide-horizontal"
      unmountOnExit
      appear
    >
      {children}
    </CSSTransition>
  );
};

export default FadeAndSlideHorizontal;