import ResumePDF from '../assets/files/Freeman_Bacon_Resume.pdf';

import './resume-button.styles.scss';

const ResumeButton = () => {
    return (
        <div className="btn fw-bold fs-6 mt-4 rounded-pill resume-button">
            <a href={ResumePDF} download="Freeman_Bacon_Resume.pdf" style={{ textDecoration: 'none' }}>
                RESUME
            </a>
        </div>
    );
};

export default ResumeButton;