import React, { useState, useEffect } from 'react';

import MobileMenuButton from './mobile-menu-button.component';
import ContentViewHolder from './content-view-holder.component';
import MobileMenu from './mobile-menu.component';
import DesktopMenu from './desktop-menu.component';
import DesktopHeader from './desktop-header.component';

import HomeView from './home-view.component';
import ProjectsView from './projects-view.component';
import AboutView from './about-view.component';
import EducationView from './education-view.component';
import ContactView from './contact-view.component';

const Main = () => {
    const [currentView, setCurrentView] = useState('home');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [shouldTransition, setShouldTransition] = useState(true);

    // const changeView = (view) => {
    //     setCurrentView(view);
    //     console.log(view);
    // };

    const changeView = (view) => {
        if (currentView !== view) {
            setShouldTransition(false);
            setTimeout(() => {
                setCurrentView(view);
                setShouldTransition(true);
            }, 300); // Match the duration of your exit animation
        }
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderView = () => {
        switch (currentView) {
            case 'home':
                return <HomeView />; // Replace with your desired content for the home view
            case 'projects':
                return <ProjectsView />; // Replace with your desired content for the projects view
            case 'about-me':
                return <AboutView />; // Replace with your desired content for the contact view
            case 'education':
                return <EducationView />; // Replace with your desired content for the education view
            case 'contact':
                return <ContactView />;
            default:
                return <HomeView />;
        }
    };

    const renderContent = () => {
        if (windowWidth < 993) {
            return (
                <div
                    className="container-fluid bg-light position-relative h-100 m-0 p-0 mx-auto"
                    style={{ margin: 0 }}
                >
                    <MobileMenuButton />
                    <MobileMenu changeView={changeView} />
                    <ContentViewHolder
                        renderView={renderView}
                        currentView={currentView}
                        shouldTransition={shouldTransition}
                    />
                </div>
            );
        } else {
            return (
                <div
                    className="container-fluid position-relative d-flex text-dark w-100 p-0"
                    style={{
                        maxWidth: 1280,
                        margin: '10vh auto',
                        minHeight: '80vh',
                        maxHeight: '80vh',
                        borderRadius: '25px',
                        // boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                        // background: 'linear-gradient(90deg, rgba(235, 235, 235, .75), rgba(245, 245, 245, .95))',
                    }}
                >
                    <DesktopHeader />
                    <ContentViewHolder
                        renderView={renderView}
                        currentView={currentView}
                        shouldTransition={shouldTransition}
                    />
                    <DesktopMenu
                        changeView={changeView}
                        currentView={currentView}
                    />
                </div>
            );
        }
    };

    // return (
    //     <div
    //         className="container-fluid bg-info position-relative h-100 m-0 p-0 mx-auto"
    //         style={{ maxWidth: 1000 }}
    //     >
    //         <DesktopMenu changeView={changeView} />
    //         <MobileMenu changeView={changeView} />
    //         <MobileMenuButton />
    //         <ContentViewHolder renderView={renderView} />
    //     </div>
    // );

    return renderContent();
};

export default Main;
