import React from 'react';
import { Helmet } from 'react-helmet-async';
import Avatar from '../assets/images/self_portrait_1.png';

const SEOData = ({ description, type, title }) => {
    return (
        <Helmet>
            <meta name="description" content={description} />
            <meta property='og:type' content={type} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={Avatar} />
            <meta name='twitter:card' content={type} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
        </Helmet>
    );
};

export default SEOData;