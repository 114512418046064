import Avatar from '../assets/images/self_portrait_1.png';

const AboutView = () => {
    const skills = [
        {
            name: 'Front-End',
            tech: ['HTML', 'CSS', 'JavaScript', 'React', 'Bootstrap', 'Sass', 'WordPress'],
        },
        {
            name: 'Back-End',
            tech: ['PHP', 'Node.js', 'Express', 'Python', 'Flask', 'Ruby on Rails', 'Java'],
        },
        {
            name: 'Databases',
            tech: ['SQL', 'PostgreSQL', 'MongoDB', 'SQLite', 'MySQL', 'Firebase', 'SQLalchemy'],
        },
        {
            name: 'Dev Tools',
            tech: ['Git', 'GitHub', 'Heroku', 'Netlify', 'VS Code'],
        },
        {
            name: 'Design & Media',
            tech: ['Figma', 'Adobe XD', 'Photoshop', 'Illustrator', 'Premiere Pro'],
        },
        {
            name: '3D & Games',
            tech: ['Unity', 'Blender', 'C#', 'JavaScript', 'Python'],
        },
        {
            name: 'Machine Learning',
            tech: ['Python', 'TensorFlow', 'Keras', 'Scikit-learn', 'Pandas', 'Numpy', 'Matplotlib'],
        },
        {
            name: 'Other',
            tech: ['Agile', 'Scrum', 'CI/CD', 'RESTful APIs', 'JAMstack'],
        }
    ];

    return (
        <div className="container">
            <h1>About Me</h1>
            <hr />
            <div className="row align-items-start">
                <div className="col fw-light fs-4">
                    {/* Your text paragraphs go here */}
                    <div className="d-flex flex-wrap">
                        <div className="col-xl-4 col-lg-12 col-md-4 col-sm-12 align-items-center d-flex flex-column justify-content-center pe-3">
                            <img
                                src={Avatar}
                                alt="Freeman Bacon"
                                className="img-fluid rounded shadow-md mb-3"
                                style={{ maxWidth: '200px' }}
                            />
                            <div className="d-flex justify-content-center">
                                <h3>
                                    <span
                                        className="badge"
                                        style={{ backgroundColor: '#28a745' }}
                                    >
                                        AVAILABLE TO WORK
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-8 col-sm-12">
                            <p>
                                Hey, I'm Freeman, and I live in sunny Denver,
                                Colorado. I've been making websites and simple
                                games since I was a little kid and always had a
                                fascination with computers, which led me to
                                pursue a degree in Computer Science in 2020.
                                Along with an interest in art and design, I've
                                been able to create some cool stuff along the
                                way.
                            </p>
                        </div>
                    </div>
                    <p>
                        Going back to school allowed me to gain experience in
                        various domains including front-end design, full-stack
                        development, natural language processing, data analysis,
                        and game development. Each endeavor has taught me
                        valuable lessons and enriched my understanding of
                        technology's diverse applications.
                    </p>
                    <p>
                        Since I recently obtained my degree, I'm looking for
                        work in the tech sector while further developing
                        freelance skills. I'm open to any opportunities that
                        will allow me to grow as a developer and contribute to
                        the community. I'm also interested in volunteer work and
                        projects that leverage technology to make a positive
                        impact on society.
                    </p>
                    <h3>Skills & Tools</h3>
                    <p>
                        Here are some of the technologies and tools I've worked with over the last few years:<br />
                        <div className='d-flex flex-wrap w-100 justify-content-center mt-2'>
                            {skills.map((skill, index) => (
                                <div
                                    key={index}
                                    className="d-flex flex-column badge bg-light text-dark m-1 text-start border border-1 border-secondary"
                                    style={{
                                        width: '200px',
                                        backgroundColor: '#333333',
                                        color: '#f8f9fa',
                                        borderRadius: '0.8rem'
                                    }}
                                >
                                    <center><div className='p-1 pb-2'>{skill.name}</div></center>
                                    {skill.tech.map((tech, index) => (
                                        <span key={index} className="badge text-light rounded-pill m-1" style={{ backgroundColor: '#333333' }}>
                                            {tech}
                                        </span>
                                    ))}
                                </div>
                            ))}    
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutView;
