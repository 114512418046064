import './project-card.styles.scss';

const ProjectCard = ({ project }) => {
    const { icon, title, link, description, image, tech } = project;

    return (
        <div
            className="card bg-light border border-0 m-2"
            style={{
                borderRadius: '20px',
                boxShadow: '0 3px 3px rgba(0, 0, 0, 0.5)',
            }}
        >
            <div
                className="w-100"
                style={{
                    backgroundColor: '#333333',
                    borderRadius: '20px 20px 0 0',
                    height: '20px',
                }}
            ></div>
            <div
                className="row g-0 h-100"
                style={{
                    maxWidth: '400px',
                    backgroundColor: 'rgba(235,235,235,1)',
                    // backgroundImage: 'linear-gradient(135deg, rgba(189,220,233,1), rgba(245,245,245,1), rgba(189,220,233,1))',
                    // backgroundImage: 'linear-gradient(180deg, rgba(189,220,233,1), rgba(245,245,245,1) 25%, rgba(245,245,245,1) 75%, rgba(189,220,233,1))',
                    // backgroundImage: 'linear-gradient(109.6deg, rgba(156,252,248,.4) 11.2%, rgba(110,123,251,.4) 91.1%)',
                    borderRadius: '0px',
                    // boxShadow: '0 3px 3px rgba(0, 0, 0, 0.5)',
                }}
            >
                <div
                    className="d-flex w-100 justify-content-center pt-3"
                    style={{ overflow: 'hidden' }}
                >
                    <img
                        src={image}
                        className="card-img-top"
                        alt={title}
                        style={{
                            objectFit: 'contain',
                            width: 'auto',
                            maxHeight: '300px',
                        }}
                    />
                </div>
                <div className="col-md-12" style={{ overflow: 'wrap' }}>
                    <div className="card-body">
                        <div className="d-flex flex-row align-items-center">
                            <i
                                className={`${icon} fs-4 pe-1`}
                                style={{ color: '#333333' }}
                            ></i>
                            <h5
                                className="card-title fw-bold fs-4"
                                style={{ fontVariant: 'small-caps' }}
                            >
                                {title}
                            </h5>
                        </div>
                        <hr className="m-1" />
                        DEMO:{' '}
                        {link === 'N/A' ? (
                            'N/A'
                        ) : (
                            <span className="demo-link">
                                <a href={link} target="_blank" rel="noreferrer">
                                    {link}
                                </a>
                            </span>
                        )}
                        <hr className="m-1" />
                        <p className="card-text">{description}</p>
                        <div className="d-flex flex-wrap">
                            {/* Bootstrap badges that list the tech used */}
                            {tech.map((tech) => (
                                <div
                                    className="badge rounded-pill m-1"
                                    style={{
                                        backgroundColor: '#333333',
                                        color: '#f8f9fa',
                                    }}
                                >
                                    {tech}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="w-100"
                style={{
                    backgroundColor: '#333333',
                    borderRadius: '0 0 20px 20px',
                    height: '20px',
                }}
            ></div>
        </div>
    );
};

export default ProjectCard;
