import React from 'react';
import './mobile-menu-button.styles.scss';

const MobileMenuButton = () => {
    return (
        <div
            className="d-xs-block d-flex align-content-center justify-content-center flex-wrap bg-info position-absolute top-0 end-0 mt-3 me-4 text-center"
            style={{ width: 50, height: 50, borderRadius: 50, zIndex: 10 }}
        >
            {/* <div className="text-light p-0 m-0 fs-3">. . .</div> */}
            <button
                className="menu-button btn btn-primary bg-transparent border-0"
                style={{ width: 50, height: 50, borderRadius: 50 }}
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
            >
                <i className="bi bi-list" style={{ fontSize: '1.5rem', color: 'white' }}></i>
            </button>
        </div>
    );
};

export default MobileMenuButton;
