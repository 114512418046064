import MSULogo from '../assets/images/msu_logo.png';

const EducationView = () => {
    const courses = [
        {
            name: "Image Processing and Computer Vision",
            description: "Introduces image processing techniques and computer vision concepts, focusing on image analysis and interpretation.",
            tech: ["OpenCV", "Python", "TensorFlow"]
        },
        {
            name: "Database Systems",
            description: "Explores the design, development, and management of SQL and NoSQL database systems, emphasizing data modeling and database design.",
            tech: ["SQL", "PostgreSQL", "SQLAlchemy", "MongoDB"]
        },
        {
            name: "Web Application Development",
            description: "Focuses on developing web applications in Ruby on Rails, covering both client-side and server-side programming along with database integration.",
            tech: ["Ruby on Rails", "HTML", "CSS", "JavaScript", "Bootstrap", "SQLite"]
        },
        {
            name: "Natural Language Processing",
            description: "Introduces the concepts and techniques used in natural language processing, focusing on linguistic data analysis and machine learning applications.",
            tech: ["Kaggle", "Python", "NLTK", "Seaborn", "Pandas", "Numpy", "Matplotlib", "Scikit-learn", "TensorFlow"]
        },
        {
            name: "Software Development Methods and Tools",
            description: "Project-based course that examines various methods and tools used in the software development lifecycle, including agile methodologies and version control systems.",
            tech: ["Git", "GitHub", "Agile", "PHP", "Java", "HTML", "CSS", "MySQL", "JavaScript", "Bootstrap"]
        },
        {
            name: "Computer Networks",
            description: "Covers the fundamentals of computer networking, including network architectures, protocols, tools, and technologies.",
            tech: ["Java", "TCP/IP", "UDP", "SMTP", "Packets"]
        },
        {
            name: "Operating Systems",
            description: "Studies the design and functioning of operating systems, including process management, memory management, and file systems.",
            tech: ["Linux", "Bash", "C"]
        },
        {
            name: "Algorithms and Algorithm Analysis",
            description: "Covers algorithm design and analysis, with an emphasis on efficiency, complexity, and optimization.",
            tech: ["Python"]
        }
    ];    

    return (
        <div className="container-fluid m-0 w-100">
            <h1 className="mb-3">Education</h1>
            <hr />
            <div className="row mb-3">
                <div className="col text-center">
                    <img src={MSULogo} alt="MSU Denver Logo" className="py-4" style={{ width: '300px' }} />
                    <h3>B.S. COMPUTER SCIENCE • MSU DENVER</h3>
                    <p className="lead mb-1">GRADUATION: FALL 2023</p>
                    <p className="lead">GPA: 3.84</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h4 className="text-center mb-3">Relevant Coursework:</h4>
                    <div className="row d-flex flex-wrap justify-content-center">
                        {courses.map((course, index) => (
                            <div key={index} className="col-md-6 col-lg-4 mb-4" style={{ minWidth: '275px' }}>
                                <div className="card h-100" style={{ backgroundColor: '#bcdce9'}}>
                                    <div className="card-body">
                                        <h5 className="card-title">{course.name}</h5>
                                        <p className="card-text">{course.description}</p>
                                    </div>
                                    <div className="d-flex flex-wrap p-2">
                                        {/* Bootstrap badges that list the tech used */}
                                        {course.tech.map((tech) => (
                                            <div
                                                className="badge rounded-pill m-1"
                                                style={{
                                                    backgroundColor: '#333333',
                                                    color: '#f8f9fa',
                                                }}
                                            >
                                                {tech}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EducationView;