import React, { useState, useEffect } from 'react';

import SocialLinks from './social-links.component';
import ResumeButton from './resume-button.component';   

import Avatar from '../assets/images/self_portrait_1.png';
import ContainerBG from '../assets/images/container-bg-02.png';

import './desktop-header.styles.scss';

const DesktopHeader = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const words = ['Developer', 'Designer', 'Creator']; // Add other words you want to cycle through
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
            // console.log(currentWordIndex);
        }, 3000); // Change the word every 3 seconds

        const interval2 = setInterval(() => {
            setFade((prevFade) => !prevFade);
            // console.log(fade);
        }, 1500);

        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
        };
    }, []);

    return (
        <div
            className="d-flex flex-column w-100 text-center justify-content-between align-items-center me-2"
            style={{
                maxWidth: 380,
                maxHeight: '100%',
                padding: '70px 30px 20px',
                borderRadius: '25px 25px 25px 25px',
                overflow: 'hidden',
                background: `linear-gradient(rgba(235, 235, 235, .75), rgba(245, 245, 245, .95)), url(${ContainerBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'bottom center',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                // backgroundColor: 'rgba(235, 235, 235, 1)',
            }}
        >
            <div className='m-0 p-0'>
                <img
                    src={Avatar}
                    style={{
                        width: '60%',
                        height: 'auto',
                        borderRadius: '50%',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    }}
                    className="avatar shadow-md"
                    alt="Freeman Bacon"
                />
                {/* <hr className="w-100 m-0" /> */}
                <span className='fw-bold'></span>
                <div className="mt-0 mb-1">
                    <h1 className="pt-0 mt-3 fw-bold">Freeman Bacon</h1>
                    <h3
                        className={`pt-0 fw-light ${
                            fade === false
                                ? 'fade-in move-right'
                                : 'fade-out move-left'
                        }`}
                    >
                        {words[currentWordIndex]}
                    </h3>
                </div>
            </div>
            {/* <div className='fs-2' style={{ marginTop: 5, marginBottom: 40, minWidth: '100%' }}>. . .</div> */}
            
            <div
                className="copyrights p-2 mt-3"
                style={{
                    fontSize: '.8rem',
                    fontWeight: 300,
                    // position: 'absolute',
                    // bottom: 0,
                    // left: "20%",
                }}
            >
                <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                    <SocialLinks />
                    <ResumeButton />
                </div>
                <i className="bi bi-c-circle"> </i>
                2023 Freeman Bacon
                <br />
                Built with React and Bootstrap
            </div>
        </div>
    );
};

export default DesktopHeader;
