import React, { useState, useEffect } from 'react';
import FadeAndSlideHorizontal from './fade-slide-wrapper.component';

import './content-view-holder.styles.scss';

const ContentViewHolder = ({ renderView, currentView, shouldTransition }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getMaxHeight = () => {
        return windowWidth < 993 ? '100vh' : '80vh';
    };

    const getBorderRadius = () => {
        return windowWidth < 993 ? '0px' : '25px 25px 25px 25px';
    };

    const getBoxShadow = () => {
        return windowWidth < 993 ? 'none' : '0 0 10px rgba(0, 0, 0, 0.5)';
    };

    const contentStyle = {
        maxHeight: getMaxHeight(),
        paddingTop: '30px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        borderRadius: getBorderRadius(),
        boxShadow: getBoxShadow(),
    };

    return (
        <div className="container-fluid bg-light text-dark px-3 d-flex flex-column" style={contentStyle}>
            <FadeAndSlideHorizontal in={shouldTransition}>
                <div key={currentView}>
                    {renderView()}
                </div>
            </FadeAndSlideHorizontal>
        </div>
    );
};

export default ContentViewHolder;
