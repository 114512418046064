import React, { useState, useEffect } from 'react';

// import Avatar from '../assets/images/self_portrait_1.png';

import Avatar from './avatar.component';
import SocialLinks from './social-links.component';
import ResumeButton from './resume-button.component';

const MobileMenu = ({ changeView }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        const handleResizeHeight = () => setWindowHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        window.addEventListener('resize', handleResizeHeight);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResizeHeight);
        };
    }, []);

    const handleClick = (view) => {
        changeView(view);
    };

    const getImgStyle = () => {
        if (windowWidth > 476) {
            return {
                width: '100px',
                height: 'auto',
                borderRadius: '50%',
            };
        } else if (windowWidth <= 476 || windowHeight <= 600) {
            return {
                width: '100%',
                height: '100px',
                borderRadius: '50%',
            };
        } else {
            return {
                width: '100px',
                height: 'auto',
                borderRadius: '50%',
            };
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end d-xs-block d-lg-none d-flex flex-column justify-space-between bg-light text-dark"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
            style={{
                minWidth: '300px',
                width: '40%',
                maxHeight: '100vh',
                overflow: 'hidden',
            }}
        >
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel"> </h5>
                <button
                    type="button"
                    className="btn-close text-reset bg-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div
                className="offcanvas-body text-center d-flex flex-column align-items-center justify-content-evenly"
                style={{ maxHeight: '100%' }}
            >
                <Avatar style={getImgStyle()} />
                <div className="mt-2 mb-3">
                    <h1 className="pt-3 fw-normal fs-2">Freeman Bacon</h1>
                    <h5 className="pt-0 fw-light fs-6">
                        Developer | Designer | Creator
                    </h5>
                </div>
                {/* . . . */}
                <div className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                    <button
                        className="btn bg-transparent fs-6 m-1"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleClick('home')}
                    >
                        HOME
                    </button>
                    <button
                        className="btn bg-transparent fs-6 m-1"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleClick('about-me')}
                    >
                        ABOUT ME
                    </button>
                    <button
                        className="btn bg-transparent fs-6 m-1"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleClick('projects')}
                    >
                        PROJECTS
                    </button>
                    <button
                        className="btn bg-transparent fs-6 m-1"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleClick('education')}
                    >
                        EDUCATION
                    </button>
                    <button
                        className="btn bg-transparent fs-6 m-1"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleClick('contact')}
                    >
                        CONTACT
                    </button>
                </div>
                {/* . . . */}

                <div
                    className="copyrights p-2 mt-4"
                    style={{
                        fontSize: '.8rem',
                        fontWeight: 300,
                        // position: 'absolute',
                        // bottom: 0,
                        // left: "20%",
                    }}
                >
                    <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                        <SocialLinks />
                        <ResumeButton />
                    </div>
                    <i className="bi bi-c-circle"> </i>
                    2023 Freeman Bacon
                    <br />
                    Built with React and Bootstrap
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
