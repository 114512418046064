import React, { useState, useEffect, Fragment } from 'react';

import Main from './components/main.component';
import SEOData from './components/seo-data';

import './App.css';

const App = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderContent = () => {
        if (windowWidth < 993) {
            return (
                <div
                    className="container-fluid h-100 w-100 custom-bg"
                    style={{ padding: '0', overflow: 'hidden' }}
                >
                    <Main />
                </div>
            );
        } else if ((windowWidth < 1280) & (windowWidth >= 993)) {
            return (
                <div
                    className="container-fluid h-100 w-100 custom-bg"
                    style={{ padding: '0 100px 0 30px', overflow: 'hidden' }}
                >
                    <Main />
                </div>
            );
        } else {
            return (
                <div
                    className="container-fluid h-100 w-100 custom-bg"
                    style={{ padding: '0 100px', overflow: 'hidden' }}
                >
                    <Main />
                </div>
            );
        }
    };

    return renderContent();
};

export default App;
