import React from 'react';

import SpilledCoffeeImage from '../assets/images/spilled_coffee_1.png';
import UniTrailImage from '../assets/images/final_logo_icon@2x.png';
import SudokuImage from '../assets/images/sudoku.png';
import OpenAIImage from '../assets/images/sample_input_1.png';
// import FBHero from '../assets/images/fb_hero.png';
import Avatar from '../assets/images/self_portrait_1.png';

import ProjectCard from './project-card.component';
import ProjectCardVertical from './project-card-vert.component';

import './projects-view.styles.scss';

const ProjectsView = () => {
    const projectsData = [
        {
            icon: 'bi bi-sign-turn-left-fill',
            title: 'UniTrail Indoor AR Navigation App',
            link: 'https://www.youtube.com/watch?v=m3ammgghOGg',
            description:
                'UniTrail was a project for my Senior Experience course during senior year. I was responsible for the overall design for the navigation system and integrating 3D models. Tools included Unity, Flutter, and Firebase.',
            image: UniTrailImage,
            tech: ['Team Project', 'Android', 'iOS', 'Unity', 'Flutter', 'Firebase', 'Agile', 'GitHub'],
        },
        {
            icon: 'bi bi-cart-check-fill',
            title: 'SpilledCoffee E-commerce Web App',
            link: 'https://spilledcoffee.net/',
            description:
                'SpilledCoffee was a project for my Software Development Methods and Tools course during softmore year. Since I had some front end experience, I was able to spearhead the customer-facing web app and did most of the development on it.',
            image: SpilledCoffeeImage,
            tech: ['Team Project', 'PHP', 'MySQL', 'HTML', 'CSS', 'JavaScript', 'Agile', 'GitHub'],
        },
        {
            icon: 'bi bi-chat-left-text-fill',
            title: 'OpenAI Sample Applications',
            link: 'https://github.com/FreemanDigital/OpenAI-Sample-Apps',
            description: 'Project for Natural Lamguage Processing course. Sample applications for OpenAI API. Includes an essay grader and a dream interpreter.',
            image: OpenAIImage,
            tech: ['Flask', 'OpenAI API', 'HTML', 'CSS', 'JavaScript', 'ChatGPT-4', 'GitHub'],
        },
        {
            icon: 'bi bi-9-square-fill',
            title: 'Sudoku Image Solver',
            link: 'N/A',
            description: 'Project for Computer Image Processing course. Solves valid Sudoku from image capture using Python and TensorFlow and displays solved puzzle. Involved image processing concepts to read and crop image, Python to solve sudoku, and Python to overlay solution onto original image.',
            image: SudokuImage,
            tech: ['Python', 'TensorFlow', 'OpenCV'],
        },
        {
            icon: 'bi bi-stack',
            title: 'Personal Portfolio',
            link: "N/A",
            description: 'Portfolio app showcasing projects, skills, and contact information. Built with React and Bootstrap and hosted on GitHub pages.',
            image: Avatar,
            tech: ['React', 'Bootstrap', 'Sass', 'GitHub', 'HTML', 'CSS', 'JavaScript'],
        },
    ];

    return (
        <div>
            <h1 className="w-100">Projects</h1>
            <hr />
            <div className="projects-view d-flex flex-wrap justify-content-center">
                {projectsData.map((project) => (
                    <ProjectCard project={project} />
                ))}
            </div>
        </div>
    );
};
export default ProjectsView;
