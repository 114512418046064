import React, { useState } from 'react';

const DesktopMenu = ({ changeView, currentView }) => {
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const handleIconClick = (view) => {
        changeView(view);
    };

    const handleIconHover = (icon) => {
        setHoveredIcon(icon);
    };

    const handleIconHoverExit = () => {
        setHoveredIcon(null);
    };

    const menuItems = [
        { icon: 'bi-house', pageName: 'Home' },
        { icon: 'bi-person', pageName: 'About-Me' },
        { icon: 'bi-briefcase', pageName: 'Projects' },
        { icon: 'bi-mortarboard', pageName: 'Education' },
        { icon: 'bi-envelope', pageName: 'Contact' },
    ];

    return (
        <div
            className="row bg-light text-dark text-center m-0 p-0 fs-2 py-3"
            style={{
                width: 70,
                position: 'absolute',
                right: -75,
                borderRadius: '50px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            }}
        >
            {menuItems.map((item, index) => (
                <div
                    key={index}
                    className="position-relative"
                    onMouseEnter={() => handleIconHover(item.pageName)}
                    onMouseLeave={handleIconHoverExit}
                >
                    <button
                        className={`border-0 ${currentView === item.pageName.toLowerCase() ? 'bg-info' : 'bg-transparent'}`}
                        style={{ borderRadius: '20px' }}
                        onClick={() =>
                            handleIconClick(item.pageName.toLowerCase())
                        }
                    >
                        <i
                            className={`bi ${item.icon} pb-1 ${
                                hoveredIcon === item.pageName
                                    ? 'text-secondary'
                                    : 'text-dark'
                            }`}
                            style={{ width: 50, height: 50 }}
                        />
                    </button>
                    {hoveredIcon === item.pageName && (
                        <div
                            className="text-dark bg-light border border-2 border-secondary fs-5 rounded px-2 py-1 position-absolute"
                            style={{
                                top: '5px',
                                left: '-50px',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {item.pageName}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default DesktopMenu;
