import React, { useState } from 'react';

import ResumeButton from './resume-button.component';

const ContactView = () => {
    // return (
    //     <div className="fw-light">
    //         <h1>Contact Me</h1>
    //         <hr />
    //         <div className="d-flex flex-column align-items-center text-center">
    //             <span className='fs-2'>Freeman Bacon</span>
    //             <div className='d-flex flex-row align-items-center m-0 p-0'>
    //                 <i className="bi bi-phone fs-3 p-1" style={{ width: 50, height: 50 }}></i><span className='fs-4'>720.210.4020</span>
    //             </div>
    //             <div className='d-flex flex-row align-items-center'>
    //                 <i className="bi bi-envelope fs-3 p-1" style={{ width: 50, heigh: 50 }}></i><span className='fs-4'>fbacon2@msudenver.edu</span>
    //             </div>
    //             <ResumeButton />
    //         </div>
    //     </div>
    // );
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., sending data to a server
        console.log(formData);
    };

    return (
        <div className="container">
            {/* <h2>Contact</h2>
            <hr /> */}
            {/* <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                        Message
                    </label>
                    <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </form> */}
            <div className="d-flex w-100 justify-content-center align-items-center">
                <iframe
                    title="Contact Form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdMupGxsLBW85nZdEkP1aZ3Q402OPs9ZYpGS-iLdl4eVVfx1Q/viewform?embedded=true"
                    // width="640"
                    // height="720"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    style={{ border: 'none', width: '100%', height: '900px', maxWidth: '640px'}}
                >
                    Loading…
                </iframe>                
            </div>
        </div>
    );
};

export default ContactView;
