import React from 'react';
import ParallaxContainer from '../components/parallax-container.component';
import Hero from '../assets/images/fb_hero.png'

// import './home-view.styles.scss';

const HomeView = () => {
  return (
    <div className='d-flex flex-column text-center justify-content-center align-items-center' style={{ height: '70vh' }}>
        {/* <h1 className='fs-1'>Freeman Bacon</h1>
        <h4 className='fw-light'>Developer | Designer | Creator</h4> */}
        <img src={Hero} alt="Freeman Bacon. Development and Design." className='w-100' />
    </div>   
  );
};

export default HomeView;